// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import TeachersFormDetails from 'forms/Teachers/TeachersFormDetails';
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'
import loadingIco from 'components/ui/Logo/loading_azul.gif'

export default function TeacherViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = params.id === 'new'

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Administrador`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/teachers/grid`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Administrador`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/teachers/grid`
      }
    ]
  }

  const [fields, setFields] = useState({
    ID_PROFESSOR: 0,
    NOM: '',
    COGNOM: '',
    ID_ESCOLA: '',
    UserName: '',
    Email: '',
    Password: ''
  })

  // CRUD: GET
  useEffect(() => {
    if (!isNew) {
      const getApiCall = {
        url: `teachers/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!'
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        ID_PROFESSOR: getApiCallGet.data.ID_PROFESSOR,
        NOM: getApiCallGet.data.NOM,
        COGNOM: getApiCallGet.data.COGNOM,
        ID_ESCOLA: getApiCallGet.data.ID_ESCOLA,
        UserName: getApiCallGet.data.UserName || '',
        Email: getApiCallGet.data.Email || '',
        Password: ''
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `teachers`
    let method = 'POST'

    if (!isNew) {
      method = 'PUT'
      url += `/${params.id}`
    }

    const apiCallPost = {
      url,
      method,
      body: values,
      messageOk: 'Dades guardades correctament',
      messageKo: 'Error al guardar les dades',
      redirectTo: '/teachers/grid'
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation(`/teachers/grid`)
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation(`/teachers/grid`)
      }
    }
  }, [getApiCallPost.loading, getApiCallPost.message, getApiCallPost.error, setLocation])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async () => {
    const apiCallDelete = {
      url: `teachers/${params.id}`,
      method: 'DELETE',
      messageOk: 'Dades borrades correctament',
      messageKo: 'Error al guardar les dades',
      redirectTo: '/teachers/grid'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation(`/teachers/grid`)
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation(`/teachers/grid`)
      }
    }
  }, [getApiCallDelete.loading, getApiCallDelete.message, getApiCallDelete.error, setLocation])

  // ############################################################

  return isNew || fields.ID_PROFESSOR !== 0 ? (
    <TeachersFormDetails
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      fields={fields}
      isNew={isNew}
    />
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <p
          className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco} alt="Loading..." />
        </p>
      </div>
    </div>
  )
}

TeacherViewDetail.propTypes = {
  params: PropTypes.any
}
