// import PropTypes from 'prop-types';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';
// import { useState, useContext } from 'react';
// import { UserContext } from 'contexts/UserContext';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import StudentsTableBar from './StudentsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function StudentsTable({ data, columns, handleClick, refreshData }) {
//   const navigate = useNavigate();
//   const { user } = useContext(UserContext);
//   const [selectedRows, setSelectedRows] = useState({});  // Almacena los seleccionados
//   const [iniciCheck, setIniciCheck] = useState('');  // Almacena el nuevo estado de INICI_CHECK
//   const [fiCheck, setFiCheck] = useState('');  // Almacena el nuevo estado de FI_CHECK
//   const [showForm, setShowForm] = useState(false);  // Controla la visibilidad del formulario

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Función para obtener información de un usuario de la API utilizando ID_ALUMNES
//   const fetchStudentData = async (studentId) => {
//     try {
//       const response = await fetch(`https://api-codina-af1a88eea621.herokuapp.com/students/${studentId}`);
//       const studentData = await response.json();
//       console.log('Información del alumno:', studentData);
//     } catch (error) {
//       console.error('Error al obtener información del alumno:', error);
//     }
//   };

//   // Maneja el clic del checkbox para seleccionar usuarios
//   const handleCheckboxClick = (row) => {
//     const studentId = row.original.ID_ALUMNES;

//     setSelectedRows((prev) => {
//       const newState = {
//         ...prev,
//         [studentId]: !prev[studentId], // Alterna el estado seleccionado de la fila
//       };

//       if (!prev[studentId]) {
//         // Llamada a la API cuando se selecciona el checkbox
//         fetchStudentData(studentId);
//       }

//       return newState;
//     });
//   };

//   // Función para manejar los cambios en INICI_CHECK y FI_CHECK
//   const handleIniciCheckChange = (e) => {
//     setIniciCheck(e.target.value);  // Actualiza el estado de INICI_CHECK
//   };

//   const handleFiCheckChange = (e) => {
//     setFiCheck(e.target.value);  // Actualiza el estado de FI_CHECK
//   };



//   // Función para aplicar el cambio a todos los usuarios seleccionados
// const handleApplyChanges = async () => {
//   const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);

//   // Verificar si hay usuarios seleccionados
//   if (selectedUsers.length === 0) {
//     console.log('No hay usuarios seleccionados');
//     return;
//   }

//   console.log('Usuarios seleccionados para aplicar cambios:', selectedUsers);

//   // Aplicar los cambios y actualizar la tabla localmente
//   for (const userId of selectedUsers) {
//     try {
//       // const response = await fetch(`https://api-codina-af1a88eea621.herokuapp.com/students/${userId}`, {
//       const response = await fetch(`http://localhost:5000/students/${userId}`, {
//         method: 'PATCH',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           INICI_CHECK: iniciCheck,
//           FI_CHECK: fiCheck,
//         }),
//       });

//       if (response.ok) {
//         console.log(`Usuario ${userId} actualizado con INICI_CHECK: ${iniciCheck}, FI_CHECK: ${fiCheck}`);
//       } else {
//         console.error(`Error al actualizar el usuario ${userId}`);
//       }
//     } catch (error) {
//       console.error('Error en la solicitud PATCH:', error);
//     }
//   }

//   // Llamar a refreshData para recargar los datos
//   await refreshData();

//   setSelectedRows({});

//   // Ocultar el formulario después de aplicar los cambios
//   setShowForm(false);
// };


//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//  return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           {/* <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Seleccionar</div>
//                 </th>
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead> */
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {/* Columna de selección (sin filtros ni sorting) */}
//                 {/* <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Seleccionar</div>
//                 </th> */}
//                 {user.role_id === 2 && (
//                   <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                     <div className="text-left text-sm ml-4">Seleccionar</div>
//                   </th>
//                 )}

//                 {/* Columnas con ordenación y filtros */}
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4 flex items-center">
//                       {/* Título de la columna */}
//                       {column.render('Header')}

//                       {/* Mostrar ícono de ordenación */}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : null}
//                     </div>

//                     {/* Mostrar el filtro si está habilitado */}
//                     <div className="mx-4">
//                       {column.canFilter ? column.render('Filter') : null}
//                     </div>
//                   </th>
//                 ))}

//                 {/* Columna de calendario (sin filtros ni sorting) */}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           }
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);

//               const studentId = row.original.ID_ALUMNES;
//               const isSelected = !!selectedRows[studentId];  // Verifica si el alumno está seleccionado

//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${isSelected ? 'bg-indigo-200' : index % 2 === 0 ? 'bg-indigo-50' : ''}`}
//                 >
//                   {/* <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <input
//                       type="checkbox"
//                       checked={isSelected}  // Refleja el estado de si está seleccionado
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCheckboxClick(row);  // Pasa toda la fila
//                       }}
//                       className="form-checkbox h-5 w-5 text-indigo-800"
//                     />
//                   </td> */}
//                   {/* Celda de selección solo para admin (role_id: 2) */}
//                   {user.role_id === 2 && (
//                     <td
//                       className="text-sm font-medium text-center cursor-pointer"
//                       style={{ width: '6.25%' }}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCheckboxClick(row); // Selecciona/deselecciona el checkbox al hacer clic en el td
//                       }}
//                     >
//                       <input
//                         type="checkbox"
//                         checked={isSelected}
//                         onChange={() => handleCheckboxClick(row)}
//                         className="form-checkbox h-5 w-5 text-indigo-800"
//                         onClick={(e) => e.stopPropagation()}
//                       />
//                     </td>
//                   )}

//                   {row.cells.map((cell) => (
//                     <td
//                       key={cell.column.id}
//                       className="text-sm font-medium pl-4 py-4"
//                       style={{ width: cell.column.width || '12.5%' }}
//                     >
//                       {cell.render('Cell')}
//                     </td>
//                   ))}
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 2} className="p-0 m-0">
//                 <StudentsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                   onSuccess={refreshData}
//                   onSuccessPracts={refreshData}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>

//       {/* Mostrar el botón para abrir el formulario si hay usuarios seleccionados */}
//       {Object.keys(selectedRows).some((key) => selectedRows[key]) && (
//         <button
//           onClick={() => setShowForm(true)}
//           className="mt-4 bg-indigo-900 text-white text-sm py-2 px-4 rounded"
//         >
//           Cambiar Check Tasca Inici y Check Tasca Final
//         </button>
//       )}

//       {/* Formulario para cambiar "INICI_CHECK" y "FI_CHECK" */}
//         {showForm && (
//     <div className="mt-6 bg-indigo-100 p-6 rounded-lg shadow-lg max-w-lg mx-auto">
//       <h3 className="text-indigo-900 font-bold text-lg mb-4">Actualizar Estado</h3>
      
//       {/* INICI_CHECK */}
//       <label htmlFor="iniciCheck" className="block text-sm font-semibold text-indigo-800 mb-2">
//         Selecciona el nuevo estado de Check Tasca Inici:
//       </label>
//       <select
//         id="iniciCheck"
//         value={iniciCheck}
//         onChange={handleIniciCheckChange}
//         className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
//       >
//         <option value="">Seleccione una opción</option>
//         <option value="0">No</option>
//         <option value="1">Sí</option>
//         <option value="2">Pendent</option>
//       </select>

//       {/* FI_CHECK */}
//       <label htmlFor="fiCheck" className="block text-sm font-semibold text-indigo-800 mt-6 mb-2">
//         Selecciona el nuevo estado de Check Tasca Final:
//       </label>
//       <select
//         id="fiCheck"
//         value={fiCheck}
//         onChange={handleFiCheckChange}
//         className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
//       >
//         <option value="">Seleccione una opción</option>
//         <option value="0">No</option>
//         <option value="1">Sí</option>
//         <option value="2">Pendent</option>
//       </select>

//       {/* Botón Aplicar Cambios */}
//       <button
//         onClick={handleApplyChanges}
//         className="w-full mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 rounded-md shadow-lg transition ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//       >
//         Aplicar cambios
//       </button>
//     </div>
//   )}

//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
//   refreshData: PropTypes.func.isRequired,
// };

// 
// ANTESSSS BUENAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
//

import PropTypes from 'prop-types';
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from 'contexts/UserContext';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// COMPONENTS
import FilterUp from 'components/icons/FilterUp';
import FilterDown from 'components/icons/FilterDown';
import SchoolsTableBar from './SchoolsTableBar';
import StudentsTableBar from './StudentsTableBar';
import { FaCalendarAlt } from 'react-icons/fa';
import { DateRangeColumnFilter } from 'components/Students/DateRangeColumnFilter';

export default function StudentsTable({ data, columns, handleClick, refreshData }) {
  if (!data) return null; // Asegúrate de que 'data' esté definido

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [selectedRows, setSelectedRows] = useState({});  // Almacena los seleccionados
  const [iniciCheck, setIniciCheck] = useState('');  // Almacena el nuevo estado de INICI_CHECK
  const [fiCheck, setFiCheck] = useState('');  // Almacena el nuevo estado de FI_CHECK
  const [showForm, setShowForm] = useState(false);  // Controla la visibilidad del formulario

  const handleCalendarClick = (student) => {
    navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
  };

  // Maneja el clic del checkbox para seleccionar usuarios
  const handleCheckboxClick = (row) => {
    const studentId = row.original.ID_ALUMNES;

    setSelectedRows((prev) => {
      const newState = {
        ...prev,
        [studentId]: !prev[studentId], // Alterna el estado seleccionado de la fila
      };

      return newState;
    });
  };

  // Función para manejar los cambios en INICI_CHECK y FI_CHECK
  const handleIniciCheckChange = (e) => {
    setIniciCheck(e.target.value);  // Actualiza el estado de INICI_CHECK
  };

  const handleFiCheckChange = (e) => {
    setFiCheck(e.target.value);  // Actualiza el estado de FI_CHECK
  };

  // Función para aplicar el cambio a todos los usuarios seleccionados
  const handleApplyChanges = async () => {
    const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);

    if (selectedUsers.length === 0) {
      console.log('No hay usuarios seleccionados');
      return;
    }

    console.log('Usuarios seleccionados para aplicar cambios:', selectedUsers);

    for (const userId of selectedUsers) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/students/${userId}`, {
        // const response = await fetch(`http://localhost:5000/students/${userId}`, {
        // const response = await fetch(`https://api-codina-af1a88eea621.herokuapp.com/students/${userId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            INICI_CHECK: iniciCheck,
            FI_CHECK: fiCheck,
          }),
        });

        if (response.ok) {
          console.log(`Usuario ${userId} actualizado con INICI_CHECK: ${iniciCheck}, FI_CHECK: ${fiCheck}`);
        } else {
          console.error(`Error al actualizar el usuario ${userId}`);
        }
      } catch (error) {
        console.error('Error en la solicitud PATCH:', error);
      }
    }

    await refreshData();
    setSelectedRows({});
    setShowForm(false);
  };

  const fetchLockedStudents = async () => {
    try {
      const response = await fetch( process.env.REACT_APP_API_URL + '/students');
      const students = await response.json();

      // Filtrar los alumnos que tienen ambos CHECK_TASCA_INICI y CHECK_TASCA_FINAL en 1
      const lockedStudents = students.filter(
        (student) => student.CHECK_TASCA_INICI === 1 && student.CHECK_TASCA_FINAL === 1
      );

      return lockedStudents.map(student => student.ID_ALUMNES);  // Devuelve solo los ID_ALUMNES de los bloqueados
    } catch (error) {
      console.error('Error fetching students:', error);
      return [];
    }
  };

    const [lockedStudentIds, setLockedStudentIds] = useState([]);

    useEffect(() => {
      // Obtener los alumnos bloqueados al cargar el componente
      const getLockedStudents = async () => {
        const lockedIds = await fetchLockedStudents();
        setLockedStudentIds(lockedIds);  // Guardar los IDs de los alumnos bloqueados en el estado
      };

      getLockedStudents();
    }, []);

  // Función para descargar el archivo Excel con los datos seleccionados

// const downloadSelectedPractices = async () => {
//   const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);

//   if (selectedUsers.length === 0) {
//     console.log('No hay usuarios seleccionados');
//     return;
//   }

//   try {
//     const formattedData = [];

//     // Obtener los datos para cada usuario seleccionado
//     for (const userId of selectedUsers) {
//       const response = await fetch(process.env.REACT_APP_API_URL + `/studentsTeacher/${userId}`);
//       // const response = await fetch(`http://localhost:5000/studentsTeacher/${userId}`);
//       // const response = await fetch(`https://api-codina-af1a88eea621.herokuapp.com/studentsTeacher/${userId}`);
//       const userData = await response.json();

//       formattedData.push({
//         "CODI BECARI": userData.CODI_BECARI,
//         "DNI NIE PASS": userData.DNI,
//         "COGNOMS": userData.COGNOM,
//         "NOM": userData.NOM,
//         "INICI PRACTIQUES": userData.INICI_PRACTIQUES,
//         "FI PRACTIQUES": userData.FI_PRACTIQUES,
//         "GENER": userData.GENER,
//         "FEBRER": userData.FEBRER,
//         "MARÇ": userData.MARÇ,
//         "ABRIL": userData.ABRIL,
//         "MAIG": userData.MAIG,
//         "JUNY": userData.JUNY,
//         "JULIOL": userData.JULIOL,
//         "AGOST": userData.AGOST,
//         "SETEMBRE": userData.SETEMBRE,
//         "OCTUBRE": userData.OCTUBRE,
//         "NOVEMBRE": userData.NOVEMBRE,
//         "DESEMBRE": userData.DESEMBRE
//       });
//     }

//     if (formattedData.length === 0) {
//       console.log('No se encontraron datos para los usuarios seleccionados');
//       return;
//     }

//     // Crear el archivo Excel
//     const worksheet = XLSX.utils.json_to_sheet(formattedData);
//     const workbook = {
//       Sheets: { 'Pràctiques': worksheet },
//       SheetNames: ['Pràctiques']
//     };

//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     const blobData = new Blob([excelBuffer], { type: 'application/octet-stream' });
//     saveAs(blobData, 'Practiques_Seleccionades.xlsx');

//   } catch (error) {
//     console.error('Error al descargar las prácticas:', error);
//   }
// };

// DESCARGAR EXCEL ALUMNOS SELECCIONADOS PARA LAS PRACTICAS!
const downloadSelectedPractices = async () => {
  const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);

  if (selectedUsers.length === 0) {
    console.log('No hay usuarios seleccionados');
    return;
  }

  try {
    const formattedData = [];

    // Obtener los datos para cada usuario seleccionado
    for (const userId of selectedUsers) {
      const response = await fetch(process.env.REACT_APP_API_URL + `/studentsTeacher/${userId}`);
      const userData = await response.json();

      // Formatear las fechas de inicio y fin de prácticas
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van del 0 al 11
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

      formattedData.push({
        "CODI BECARI": userData.CODI_BECARI,
        "DNI NIE PASS": userData.DNI,
        "COGNOMS": userData.COGNOM,
        "NOM": userData.NOM,
        "INICI PRACTIQUES": formatDate(userData.INICI_PRACTIQUES),
        "FI PRACTIQUES": formatDate(userData.FI_PRACTIQUES),
        "GENER": userData.GENER,
        "FEBRER": userData.FEBRER,
        "MARÇ": userData.MARÇ,
        "ABRIL": userData.ABRIL,
        "MAIG": userData.MAIG,
        "JUNY": userData.JUNY,
        "JULIOL": userData.JULIOL,
        "AGOST": userData.AGOST,
        "SETEMBRE": userData.SETEMBRE,
        "OCTUBRE": userData.OCTUBRE,
        "NOVEMBRE": userData.NOVEMBRE,
        "DESEMBRE": userData.DESEMBRE
      });
    }

    if (formattedData.length === 0) {
      console.log('No se encontraron datos para los usuarios seleccionados');
      return;
    }

    // Crear el archivo Excel con exceljs
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Pràctiques');

    // Definir las columnas
    worksheet.columns = [
      { header: 'CODI BECARI', key: 'CODI BECARI', width: 20 },
      { header: 'DNI NIE PASS', key: 'DNI NIE PASS', width: 20 },
      { header: 'COGNOMS', key: 'COGNOMS', width: 30 },
      { header: 'NOM', key: 'NOM', width: 30 },
      { header: 'INICI PRACTIQUES', key: 'INICI PRACTIQUES', width: 20 },
      { header: 'FI PRACTIQUES', key: 'FI PRACTIQUES', width: 20 },
      { header: 'GENER', key: 'GENER', width: 10 },
      { header: 'FEBRER', key: 'FEBRER', width: 10 },
      { header: 'MARÇ', key: 'MARÇ', width: 10 },
      { header: 'ABRIL', key: 'ABRIL', width: 10 },
      { header: 'MAIG', key: 'MAIG', width: 10 },
      { header: 'JUNY', key: 'JUNY', width: 10 },
      { header: 'JULIOL', key: 'JULIOL', width: 10 },
      { header: 'AGOST', key: 'AGOST', width: 10 },
      { header: 'SETEMBRE', key: 'SETEMBRE', width: 10 },
      { header: 'OCTUBRE', key: 'OCTUBRE', width: 10 },
      { header: 'NOVEMBRE', key: 'NOVEMBRE', width: 10 },
      { header: 'DESEMBRE', key: 'DESEMBRE', width: 10 }
    ];

    // Rellenar los datos
    formattedData.forEach((data) => {
      worksheet.addRow(data);
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFCC00' }  // Color amarillo
      };
      cell.font = { bold: true, color: { argb: 'FF0000FF' } };  // Texto en negrita y azul
      cell.alignment = { vertical: 'middle', horizontal: 'center' };  // Alinear en el centro
    });

    // Aplicar validación de datos a las columnas de meses
    const monthColumns = ['GENER', 'FEBRER', 'MARÇ', 'ABRIL', 'MAIG', 'JUNY', 'JULIOL', 'AGOST', 'SETEMBRE', 'OCTUBRE', 'NOVEMBRE', 'DESEMBRE'];

    monthColumns.forEach((col) => {
      worksheet.getColumn(col).eachCell((cell, rowNumber) => {
        if (rowNumber !== 1) {  // Excluir la cabecera
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCE5FF' }  // Color azul claro
          };
        }

        cell.protection = { locked: false };  // Hacer la columna editable

        // Agregar validación de datos para que solo acepte valores entre 0 y 30, sin decimales
        cell.dataValidation = {
          type: 'whole',
          operator: 'between',
          formulae : [ 0 ,  30 ], 
          showErrorMessage: true,
          errorTitle: 'Valor inválido',
          error: 'Por favor, ingresa un número entero entre 0 y 30.',
        };
      });
    });

    // Aplicar color amarillo pastel a "CODI BECARI", "DNI", "NOM" y "COGNOMS"
    ['CODI BECARI', 'DNI NIE PASS', 'NOM', 'COGNOMS', 'INICI PRACTIQUES', 'FI PRACTIQUES'].forEach((col) => {
      worksheet.getColumn(col).eachCell((cell, rowNumber) => {
        if (rowNumber !== 1) {  // Excluir la cabecera
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFF2CC' }  // Color amarillo pastel
          };
        }
      });
    });

    // Bloquear la hoja para proteger las celdas no editables
    worksheet.protect('Gest1net+Cod1na', {
      selectLockedCells: true,
      selectUnlockedCells: true,
    });

    // Generar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blobData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blobData, 'Practiques_Seleccionades.xlsx');

  } catch (error) {
    console.error('Error al descargar las prácticas:', error);
  }
};


  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   page,
  //   nextPage,
  //   previousPage,
  //   canNextPage,
  //   canPreviousPage,
  //   pageOptions,
  //   setPageSize,
  //   state,
  //   prepareRow
  // } = useTable(
  //   { columns, data, initialState: { pageSize: 25 } },
  //   useGlobalFilter,
  //   useFilters,
  //   useSortBy,
  //   usePagination
  // );

  // const { pageIndex, pageSize } = state;

  // function dateBetweenFilterFn(rows, id, filterValue) {
  //   let [min, max] = filterValue;

  //   min = min ? new Date(min) : undefined;
  //   max = max ? new Date(max) : undefined;

  //   return rows.filter(row => {
  //     const rowDate = new Date(row.values[id]);
  //     return (
  //       (min ? rowDate >= min : true) &&
  //       (max ? rowDate <= max : true)
  //     );
  //   });
  // }

  // dateBetweenFilterFn.autoRemove = val => !val; // Remueve el filtro si no hay valores

  function dateBetweenFilterFn(rows, id, filterValue) {
    let [min, max] = filterValue;

    // Convertimos min y max a formato Date de JavaScript (YYYY-MM-DD)
    min = min ? new Date(min) : undefined;
    max = max ? new Date(max) : undefined;

    return rows.filter(row => {
      const rowDate = row.values[id];
      // Aquí convertimos la fecha del row (que está en formato DD-MM-YYYY) a YYYY-MM-DD para la comparación
      const [day, month, year] = rowDate.split('-');
      const parsedRowDate = new Date(`${year}-${month}-${day}`);

      return (
        (min ? parsedRowDate >= min : true) &&
        (max ? parsedRowDate <= max : true)
      );
    });
  }

  // dateBetweenFilterFn.autoRemove = val => !val; // Remueve el filtro si no hay valores


  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   page,
  //   prepareRow,
  //   state: { pageSize, pageIndex },
  //   filteredRows,  // Datos filtrados
  //   nextPage,
  //   previousPage,
  //   canNextPage,
  //   canPreviousPage,
  //   pageOptions,
  //   setPageSize,
  // } = useTable(
  //   {
  //     columns,
  //     data,
  //     initialState: { pageSize: 25 },  // Tamaño de página inicial
  //   },
  //   useGlobalFilter,
  //   useFilters,
  //   useSortBy,
  //   usePagination
  // );
// bona arriba

const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageSize, pageIndex },
    filteredRows,  // Datos filtrados
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      filterTypes: { dateBetween: dateBetweenFilterFn },
      initialState: { pageSize: 25 },  // Tamaño de página inicial
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );


  return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        <table
          className="table-auto min-w-full border-2 border-indigo-800"
          {...getTableProps()}
        >
          <thead className="bg-indigo-900">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-indigo-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {(user.role_id === 1 || user.role_id === 2) && (
                  <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                    <div className="text-left text-sm ml-4">Seleccionar</div>
                  </th>
                )}
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                  >
                    <div className="text-left text-sm ml-4 flex items-center">
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown width={10} height={10} className="inline-block ml-1 -mt-1" fill="black" />
                        ) : (
                          <FilterUp width={10} height={10} className="inline-block ml-1 -mt-1" fill="black" />
                        )
                      ) : null}
                    </div>
                    <div className="mx-4">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
                <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                  <div className="text-left text-sm ml-4">Calendari</div>
                </th>
              </tr>
            ))}
          </thead>
          {/* <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row);
              const studentId = row.original.ID_ALUMNES;
              const isSelected = !!selectedRows[studentId];

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
                    ${isSelected ? 'bg-indigo-200' : index % 2 === 0 ? 'bg-indigo-50' : ''}`}
                >
                  {(user.role_id === 1 || user.role_id === 2) && (
                    <td
                      className="text-sm font-medium text-center cursor-pointer"
                      style={{ width: '6.25%' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCheckboxClick(row);
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleCheckboxClick(row)}
                        className="form-checkbox h-5 w-5 text-indigo-800"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </td>
                  )}
                  {row.cells.map((cell) => (
                    <td
                      key={cell.column.id}
                      className="text-sm font-medium pl-4 py-4"
                      style={{ width: cell.column.width || '12.5%' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                  <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCalendarClick(row.original);
                      }}
                      className="text-indigo-800 hover:text-blue-700"
                    >
                      <FaCalendarAlt size={24} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody> */}


          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row);
              const studentId = row.original.ID_ALUMNES;
              // Estructura que emmagatzema els ID's dels alumnes seleccionats
              // !! Converteix qualsevol valor en un boolean Aixi que si el StudentId està en el SelectedRows, isSelected serà true sino false.
              const isSelected = !!selectedRows[studentId];

              // Verificar si el alumno está en la lista de IDs bloqueados
              const isLocked = lockedStudentIds.includes(studentId) && user.role_id === 1;

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => !isLocked && handleClick && handleClick(e, row.original)}  // Solo permitir clic si no está bloqueado
                  className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
                    ${isSelected ? 'bg-indigo-200' : index % 2 === 0 ? 'bg-indigo-50' : ''}`}
                >
                  {(user.role_id === 1 || user.role_id === 2) && (
                    <td
                      className="text-sm font-medium text-center cursor-pointer"
                      style={{ width: '6.25%' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isLocked) handleCheckboxClick(row);  // Solo permitir la selección si no está bloqueado
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={!!selectedRows[studentId]}  // Verificar si este alumno está seleccionado
                        onChange={() => !isLocked && handleCheckboxClick(row)}  // Solo permitir cambios si no está bloqueado
                        className="form-checkbox h-5 w-5 text-indigo-800"
                        disabled={isLocked}  // Deshabilitar checkbox si está bloqueado
                      />
                    </td>
                  )}
                  {row.cells.map((cell) => (
                    <td
                      key={cell.column.id}
                      className="text-sm font-medium pl-4 py-4"
                      style={{ width: cell.column.width || '12.5%' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                  <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isLocked) handleCalendarClick(row.original);  // Solo permitir clic en el calendario si no está bloqueado
                      }}
                      className={`text-indigo-800 hover:text-blue-700 ${isLocked ? 'cursor-not-allowed opacity-50' : ''}`}
                      disabled={isLocked}  // Deshabilitar botón si está bloqueado
                    >
                      <FaCalendarAlt size={24} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={columns.length + 2} className="p-0 m-0">
                <StudentsTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  // datos={data}
                  datos={filteredRows.map(row => row.original)}
                  onSuccess={refreshData}
                  onSuccessPracts={refreshData}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      {Object.keys(selectedRows).some((key) => selectedRows[key]) && (
        user.role_id === 1 ? (
          <button
            onClick={downloadSelectedPractices}
            className="mt-4 bg-green-600 text-white text-sm py-2 px-4 rounded"
          >
            Descarregar Plantilla Pràctiques
          </button>
        ) : (
          <button onClick={() => setShowForm(true)} className="mt-4 bg-indigo-900 text-white text-sm py-2 px-4 rounded">
            Cambiar Check Tasca Inici y Check Tasca Final
          </button>
        )
      )}

      {showForm && user.role_id === 2 && (
        <div className="mt-6 bg-indigo-100 p-6 rounded-lg shadow-lg max-w-lg mx-auto">
          <h3 className="text-indigo-900 font-bold text-lg mb-4">Actualizar Estado</h3>
          <label htmlFor="iniciCheck" className="block text-sm font-semibold text-indigo-800 mb-2">
            Selecciona el nuevo estado de Check Tasca Inici:
          </label>
          <select
            id="iniciCheck"
            value={iniciCheck}
            onChange={handleIniciCheckChange}
            className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
          >
            <option value="">Seleccione una opción</option>
            <option value="0">No</option>
            <option value="1">Sí</option>
            <option value="2">Pendent</option>
          </select>

          <label htmlFor="fiCheck" className="block text-sm font-semibold text-indigo-800 mt-6 mb-2">
            Selecciona el nuevo estado de Check Tasca Final:
          </label>
          <select
            id="fiCheck"
            value={fiCheck}
            onChange={handleFiCheckChange}
            className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
          >
            <option value="">Seleccione una opción</option>
            <option value="0">No</option>
            <option value="1">Sí</option>
            <option value="2">Pendent</option>
          </select>

          <button onClick={handleApplyChanges} className="w-full mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 rounded-md shadow-lg transition ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Aplicar cambios
          </button>
        </div>
      )}
    </div>
  );
}

StudentsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  refreshData: PropTypes.func.isRequired,
};
