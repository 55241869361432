// // PropTypes & HOOKS
// import PropTypes from 'prop-types'
// import { useContext, useEffect, useState } from 'react'
// import useFetch from 'hooks/useFetch'

// // COMPONENTS
// import SchoolsFormDetails from 'forms/Schools/SchoolsFormDetails';
// import { toast } from 'react-toastify'

// // CONTEXTS
// import { useLocation } from 'wouter'
// import { TitleContext } from 'contexts/TitleContext'

// // ASSETS
// import { ImArrowLeft2 } from 'react-icons/im'
// import loadingIco from 'components/ui/Logo/loading_azul.gif'

// export default function SchoolsViewDetail({ params }) {
//   // Contextos:
//   const [, setLocation] = useLocation()
//   const { setTitle } = useContext(TitleContext)

//   // useFetch():
//   const [getApiCallGet, setApiCallGet] = useFetch()
//   const [getApiCallPost, setApiCallPost] = useFetch()
//   const [getApiCallDelete, setApiCallDelete] = useFetch()

//   // Variables:
//   const isNew = params.id === 'new'

//   // ############################################################

//   // Título (PUT):
//   const titleEdit = {
//     name: `Modificar Escola`,
//     buttons: [
//       {
//         id: 'btnBack',
//         name: (
//           <span className="flex flex-row items-center w-full">
//             <span className="pl-3 pr-1">
//               <ImArrowLeft2 size={20} />
//             </span>
//             <span className="pl-1 pr-3">Tornar</span>
//           </span>
//         ),
//         link: `/schools/grid`
//       }
//     ],
//     deleteMTBtn: true,
//     mtId: params.id
//   }

//   // Título (POST):
//   const titleCreate = {
//     name: `Registrar Escola`,
//     buttons: [
//       {
//         id: 'btnBack',
//         name: (
//           <span className="flex flex-row items-center w-full">
//             <span className="pl-3 pr-1">
//               <ImArrowLeft2 size={20} />
//             </span>
//             <span className="pl-1 pr-3">Tornar</span>
//           </span>
//         ),
//         link: `/schools/grid`
//       }
//     ]
//   }

//   const [fields, setFields] = useState({
//     ID_ESCOLA: 0,
//     NOM: '',
//     CODI: '',
//     CENTRE: '',
//     CCC: ''
//   })

//   // CRUD: GET
//   useEffect(() => {
//     if (!isNew) {
//       const getApiCall = {
//         url: `schools/${params.id}`,
//         method: 'GET',
//         successMessage: null,
//         failureMessage: 'Error de càrrega!'
//       }
//       setApiCallGet(getApiCall)

//       setTitle(titleEdit)
//     } else {
//       setTitle(titleCreate)
//     }
//   }, [])

//   // * Recoger datos API: GET
//   useEffect(() => {
//     if (getApiCallGet.data) {
//       setFields({
//         ID_ESCOLA: getApiCallGet.data.ID_ESCOLA,
//         NOM: getApiCallGet.data.NOM,
//         CODI: getApiCallGet.data.CODI,
//         CENTRE: getApiCallGet.data.CENTRE,
//         CCC: getApiCallGet.data.CCC
//       })
//     }
//   }, [getApiCallGet.data])

//   // ############################################################

//   // CRUD: POST / PUT
//   const handleSubmit = async (values) => {
//     let url = `schools`
//     let method = 'POST'

//     if (!isNew) {
//       method = 'PUT'
//       url += `/${params.id}`
//     }

//     const apiCallPost = {
//       url,
//       method,
//       body: values
//     }
//     await setApiCallPost(apiCallPost)
//   }

//   // * Mostrar mensaje API: POST / PUT
//   // ! Mostrar error API: POST / PUT
//   useEffect(() => {
//     if (getApiCallPost.isCalled) {
//       const successMessage = (message) =>
//         toast.success(message, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       const errorMessage = (error) =>
//         toast.error(error, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       if (getApiCallPost.message) {
//         successMessage(getApiCallPost.message)
//         setLocation(`/schools/grid`)
//       }

//       if (getApiCallPost.error) {
//         errorMessage(getApiCallPost.error)
//         setLocation(`/schools/grid`)
//       }
//     }
//   }, [getApiCallPost.loading, getApiCallPost.message, getApiCallPost.error, setLocation])

//   // ############################################################

//   // CRUD: DELETE
//   const handleDelete = async () => {
//     const apiCallDelete = {
//       url: `schools/${params.id}`,
//       method: 'DELETE'
//     }
//     await setApiCallDelete(apiCallDelete)
//   }

//   // * Mostrar mensaje API: DELETE
//   // ! Mostrar error API: DELETE
//   useEffect(() => {
//     if (getApiCallDelete.isCalled) {
//       const successMessage = (message) =>
//         toast.success(message, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       const errorMessage = (error) =>
//         toast.error(error, {
//           position: toast.POSITION.TOP_CENTER
//         })

//       if (getApiCallDelete.message) {
//         successMessage(getApiCallDelete.message)
//         setLocation(`/schools/grid`)
//       }

//       if (getApiCallDelete.error) {
//         errorMessage(getApiCallDelete.error)
//         setLocation(`/schools/grid`)
//       }
//     }
//   }, [getApiCallDelete.loading, getApiCallDelete.message, getApiCallDelete.error, setLocation])

//   // ############################################################

//   return isNew || fields.ID_ESCOLA !== 0 ? (
//     <SchoolsFormDetails
//       handleSubmit={handleSubmit}
//       handleDelete={handleDelete}
//       fields={fields}
//       isNew={isNew}
//     />
//   ) : (
//     <div>
//       <div className="flex items-center content-center justify-center w-full h-full">
//         <p
//           className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
//           title="Carregant..."
//         >
//           Carregant!
//           <img src={loadingIco} alt="Loading..." />
//         </p>
//       </div>
//     </div>
//   )
// }

// SchoolsViewDetail.propTypes = {
//   params: PropTypes.any
// }


// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import useFetch from 'hooks/useFetch'

// COMPONENTS
import SchoolsFormDetails from 'forms/Schools/SchoolsFormDetails'
import { toast } from 'react-toastify'

// CONTEXTS
import { useLocation } from 'wouter'
import { TitleContext } from 'contexts/TitleContext'

// ASSETS
import { ImArrowLeft2 } from 'react-icons/im'
import loadingIco from 'components/ui/Logo/loading_azul.gif'

export default function SchoolsViewDetail({ params }) {
  // Contextos:
  const [, setLocation] = useLocation()
  const { setTitle } = useContext(TitleContext)

  // useFetch():
  const [getApiCallGet, setApiCallGet] = useFetch()
  const [getApiCallPost, setApiCallPost] = useFetch()
  const [getApiCallDelete, setApiCallDelete] = useFetch()

  // Variables:
  const isNew = params.id === 'new'

  // ############################################################

  // Título (PUT):
  const titleEdit = {
    name: `Modificar Escola`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/schools/grid`
      }
    ],
    deleteMTBtn: true,
    mtId: params.id
  }

  // Título (POST):
  const titleCreate = {
    name: `Registrar Escola`,
    buttons: [
      {
        id: 'btnBack',
        name: (
          <span className="flex flex-row items-center w-full">
            <span className="pl-3 pr-1">
              <ImArrowLeft2 size={20} />
            </span>
            <span className="pl-1 pr-3">Tornar</span>
          </span>
        ),
        link: `/schools/grid`
      }
    ]
  }

  const [fields, setFields] = useState({
    ID_ESCOLA: 0,
    NOM: '',
    CODI: '',
    CENTRE: '',
    CCC: '',
    CODI_BECARI: ''
  })

  // CRUD: GET
  useEffect(() => {
    if (!isNew) {
      const getApiCall = {
        url: `schools/${params.id}`,
        method: 'GET',
        successMessage: null,
        failureMessage: 'Error de càrrega!'
      }
      setApiCallGet(getApiCall)

      setTitle(titleEdit)
    } else {
      setTitle(titleCreate)
    }
  }, [])

  // * Recoger datos API: GET
  useEffect(() => {
    if (getApiCallGet.data) {
      setFields({
        ID_ESCOLA: getApiCallGet.data.ID_ESCOLA,
        NOM: getApiCallGet.data.NOM,
        CODI: getApiCallGet.data.CODI,
        CENTRE: getApiCallGet.data.CENTRE,
        CCC: getApiCallGet.data.CCC,
        CODI_BECARI: getApiCallGet.data.CODI_BECARI
      })
    }
  }, [getApiCallGet.data])

  // ############################################################

  // CRUD: POST / PUT
  const handleSubmit = async (values) => {
    let url = `schools`
    let method = 'POST'

    if (!isNew) {
      method = 'PUT'
      url += `/${params.id}`
    }

    const apiCallPost = {
      url,
      method,
      body: values,
      messageOk: 'Dades guardades correctament',
      messageKo: 'Error al guardar les dades',
      redirectTo: '/schools/grid'
    }
    await setApiCallPost(apiCallPost)
  }

  // * Mostrar mensaje API: POST / PUT
  // ! Mostrar error API: POST / PUT
  useEffect(() => {
    if (getApiCallPost.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallPost.message) {
        successMessage(getApiCallPost.message)
        setLocation('/schools/grid')
      }

      if (getApiCallPost.error) {
        errorMessage(getApiCallPost.error)
        setLocation('/schools/grid')
      }
    }
  }, [getApiCallPost.isCalled, getApiCallPost.message, getApiCallPost.error, setLocation])

  // ############################################################

  // CRUD: DELETE
  const handleDelete = async () => {
    const apiCallDelete = {
      url: `schools/${params.id}`,
      method: 'DELETE',
      messageOk: 'Escola Eliminada',
      messageKo: 'Error al borrar Escola',
      redirectTo: '/schools/grid'
    }
    await setApiCallDelete(apiCallDelete)
  }

  // * Mostrar mensaje API: DELETE
  // ! Mostrar error API: DELETE
  useEffect(() => {
    if (getApiCallDelete.isCalled) {
      const successMessage = (message) =>
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER
        })

      const errorMessage = (error) =>
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER
        })

      if (getApiCallDelete.message) {
        successMessage(getApiCallDelete.message)
        setLocation('/schools/grid')
      }

      if (getApiCallDelete.error) {
        errorMessage(getApiCallDelete.error)
        setLocation('/schools/grid')
      }
    }
  }, [getApiCallDelete.isCalled, getApiCallDelete.message, getApiCallDelete.error, setLocation])

  // ############################################################

  return isNew || fields.ID_ESCOLA !== 0 ? (
    <SchoolsFormDetails
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      fields={fields}
      isNew={isNew}
    />
  ) : (
    <div>
      <div className="flex items-center content-center justify-center w-full h-full">
        <p
          className="flex flex-col-reverse items-center justify-center h-full font-extrabold text-green-700"
          title="Carregant..."
        >
          Carregant!
          <img src={loadingIco} alt="Loading..." />
        </p>
      </div>
    </div>
  )
}

SchoolsViewDetail.propTypes = {
  params: PropTypes.any
}
